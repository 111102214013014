import './App.css';

function App() {
  return (
    <div className="App">
      <a href="https://twitter.com/usestellarapp">@usestellarapp</a>
      <div className="container">
        <h1>UseStellar</h1>
        <h3>Coming soon</h3>
      </div>
    </div>
  );
}

export default App;
